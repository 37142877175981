
/*
 * HTML overrides
 */

 html, body , h1, h2, h3, h4, h5, h6, p, span , a, table, tbody, thead, td, li {
    font-feature-settings: normal;
    -ms-font-feature-settings: normal;
 }

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;

    &.is-guest {
        background-color: #f4f4f4;
    }
}

h1 {
    font-size: 1.8em;
    font-family: $base-font-family;
    border-bottom: $base-border;
    border-width: 1px;
    padding-bottom: 0.5em;
}

h2 {
    font-size: 1.2em;
    font-family: $base-font-family;
}

/*
 * Common grid panels
 *
 * The main 'content' section is an outer-container so anything within
 * that should be a gcolumn span. The following span definitions could
 * be anyhere though.
 */

.cols-full-width {
    @include fill-parent();
    @include pad();
}

.cols-narrow-centre {
    @include span-columns(6);
    @include shift(3);
    @include pad();
}

/*
 * Common classes
 */

.clearfix {
    @include clearfix;
}

.noclick {
    pointer-events: none;
}

.hidden {
    visibility: hidden;
}

/*
 * Panels
 */

.container-guest {
    $panel-width:24em;
    position: absolute;
    top: 15%;
    left: 50%;
    width: $panel-width;
    margin-left: $panel-width * -0.5;
    box-shadow: 0 0 50px rgba(#000, 0.1);
    border: $base-border;
    background: #fff;
    margin-bottom: 10%;

    h1, p {
        text-align: center;
    }
}

.container-guest-footer {
    font-size: 0.8em;
    margin: 0 1em;
    padding: 1em 0;
    border-top: $base-border;
}

.panel, .alert, .flash {
    border: $base-border;
    padding: $gutter / 2;
}

.panel {
    border-left-width: 6px;
    background: #fff;
}

.panel-spaced {
    margin: $gutter 0;
}

.panel-login {
    border: none;
    padding: $gutter;
}

.alert {
    margin: $gutter 0;
}

.flash {
    position: absolute;
    top: 35%;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    box-shadow: 0 0 50px rgba(#000, 0.1);
    padding: 1.5em 0.5em;
    border-radius: 3px;
    font-size: 1.3em;
    text-align: center;
}

.alert-success, .flash-success {
    background-color: $success-colour;
    border-color: darken($success-colour, 7%);
    color: darken($success-colour, 60%);
    a {
        color: darken($success-colour, 80%);
    }
}

.alert-error, .flash-error {
    background-color: $error-colour;
    border-color: darken($error-colour, 7%);
    color: darken($error-colour, 60%);
    a {
        color: darken($error-colour, 80%);
    }
}

.alert-notice, .flash-notice {
    background-color: $notice-colour;
    border-color: darken($notice-colour, 7%);
    color: darken($notice-colour, 60%);
    a {
        color: darken($notice-colour, 80%);
    }
}

.alert-message, .flash-message {
    background-color: $message-colour;
    border-color: darken($message-colour, 7%);
    color: darken($message-colour, 60%);
    a {
        color: darken($message-colour, 80%);
    }
}



.image-list {

    @extend .panel;

    margin-bottom: 1.5em;

    li {

        $size: 30px;

        margin-bottom: $gutter / 2;
        line-height: $size + 6px;

        &:last-child {
            margin-bottom: 0px;
        }

        div {
            padding: 3px;
            margin-right: $gutter / 2;
            display: inline-block;
            float:left;
            background-color: $brand-banner;

            &:after {
                clear: both;
            }

            img {
                width: $size;
                height: $size;
            }
        }

        .button {
            float: right;
        }
    }
}





/*
 * Header
 */

.app-header {
    background-color: #000;
    color: #fff;

    a {
        color: #fff;
    }
}

.app-header-container {
    @include outer-container;
}

.app-title {
    @include span-columns(8);
    @include pad();

    background: transparent none no-repeat $gutter 50%;
    background-size: 70px;

    .title-box {
        display: inline-block;
        margin-bottom: 1em;
    }

    margin-bottom: -1em;
}

.app-logo-image {
    float: left;
    margin-right: $gutter / 1.5;
    height: 70px;
    position: relative;
    bottom: -1em;
    top: -0.5em;

}

.app-title-company {
    display: block;
    font-weight: bold;
    font-family: $base-font-family;
    font-size: 2em;
    line-height: 1.2em;
}

.user-summary {
    @include span-columns(4);
    @include pad();

    a {
        display: block;
        text-align: right;

        .fa {
            margin-left: 0.5em;
        }
    }
}

/*
 * Nav
 */

 .app-nav {
     background-color: $brand-banner;
     color: #fff;

     a {
         color: #fff;
     }
 }

 .app-nav-container {
     @include outer-container;
 }

 .app-nav-menu {
     @include fill-parent();
     @include pad();
     padding-top: 0;
     padding-bottom: 0;
     display: block;

     li {
        display: inline-block;
        margin: 0;

        a {
            display: inline-block;
            padding: $gutter / 2;
            color: #fff;
            border-left: 1px solid rgba(#000, 0.15);

            &:hover, &:focus {
                background-color: rgba(#000, 0.1);
            }
        }

        &:last-child a {
            border-right: 1px solid rgba(#000, 0.1);
        }
     }
 }

/*
 * Main area
 */

.app-body {
    background-color: #fff;
}

.app-body-container {
    @include outer-container;
}

.breadcrumbs {
    border-bottom: $base-border;
    border-bottom-color: $brand-banner;
    padding-bottom: 1em;
    margin-bottom: 1em;
    font-size: 0.9em;
}


/*
 * Side Bar
 */

.app-sidebar-container {
    
    $sidebar-break: em(680);
    
    @include outer-container;

    .cols-left-content {
        @include span-columns(8);

        @media(max-width: $sidebar-break) {
            @include fill-parent();
            
            border-bottom: $base-border;
            padding-bottom: $base-spacing / 2;
            margin-bottom: $base-spacing / 2;
        }
    }

    .cols-right-sidebar {
        @include span-columns(4);

        @media(max-width: $sidebar-break) {
            @include fill-parent();
        }

        margin-top: 1em;
    }
}


/*
 * Footer
 */

.app-footer {
}

.app-footer-container {
    @include outer-container;
}

.footnote {
    @include fill-parent();
    @include pad();

    font-size: 0.8em;

    .send-message {
        float: right;
    }
}

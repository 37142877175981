table {
  @include font-feature-settings("kern", "liga", "tnum");
  border-collapse: collapse;
  margin: $small-spacing 0;
  // table-layout: fixed;
  width: 100%;
}

tr,
td,
th {
  vertical-align: middle;
}

th {
  border-bottom: 1px solid darken($base-border-color, 15%);
  font-weight: 600;
  padding: $small-spacing $small-spacing/2;
  text-align: left;
  vertical-align: top;

  .subheading {
    display: block;
  }
}

td {
  border-bottom: $base-border;
  padding: $small-spacing $small-spacing/2;
}

tr:nth-child(odd) {
  td {
    background-color: #f6f6f6;
  }
}

tr:hover, tr:focus {
  td {
    background-color: #e9e9e9;
  }
}

tr.highlight {
  td {
    background-color: #e3f6e9;
  }
}

tr.highlight-error {
  td {
    background-color: #fce3e4;
  }
}
#{$all-button-inputs},
button, .button {
  @include appearance(none);
  -webkit-font-smoothing: antialiased;
  background-color: $brand-main;
  border-radius: $base-border-radius;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: darken($brand-main, 15%);
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.button-icon {
    padding: 0.6em;
}

.button-icon-small {
    padding: 0.3em;
    background-color: transparent !important;
    color: $brand-main;
    vertical-align: top;
    cursor: pointer;

    &:hover,
    &:focus {
      color: darken($brand-main, 15%);
      background-color: #fff;
    }
}

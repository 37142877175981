
.row-actions {
    text-align: right;
}

.order-entry {
	td, th {
		padding: 0.5em 0;
	}
	input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea, select[multiple=multiple], textarea {
		max-width: 95%;
		margin: 0;
	}
	select {
		height: 2.5em;
		margin: 0;
		width: 95%;
	}
	th.part-code {
		width: 10%;
	}
	th.qty {
		width: 5%;
		text-align: center;
	}
	th.remove {
		width: 10%;
	}
	td.qty {
		text-align: center;
	}
}

.order-table, .return-stock-table {
	td, th {
		padding: 0.5em 0;
	}
	input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea, select[multiple=multiple], textarea {
		max-width: 95%;
		margin: 0;
	}
	th.qty, td.qty {
		text-align: center;
		input {
			text-align: center;
			max-width: 50px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	th.checkbox, td.checkbox {
		text-align: center;
	}
	td.discrepancy-text {
		padding-right: 15px;
		font-weight: bold;
	}
	th.part-detail, td.part-detail {
		width: 30%;
	}

	th.date, td.part-detail {
		width: 6.5em;
	}
}

.table-row-add {
	float: right;
}

td.strong {
	font-weight: bold;
}

.discrepancy-note-row {
	text-align: right;

	.discrepancy-text {
		padding-bottom: 1em;
		vertical-align: bottom;
	}

	.previous {
		text-align: left;
	}
}
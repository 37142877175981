// Typography
$base-font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;;
$heading-font-family: "Roboto Slab", "Georgia", "Cambria", "Times New Roman", "Times", serif;

// Font Sizes
$base-font-size: 11pt;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
// $blue: #477dca;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;

$success-colour: #E6F0C0;
$error-colour: #FCE3E4;
$notice-colour: #E5EDF9;
$message-colour: #FFF7BC;

// Font Colors
$base-background-color: #fff;
$base-font-color: $dark-gray;

// The following two colours are HTS defaults. Every occurange of these needs to appear at the top of app.blade.php as well.
$brand-main: #444; // primary buttons and links (prob. same as below but sometimes needs to be darker)
$brand-banner: #333; // nav banner colour

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($brand-main, $lightness: -5%, $alpha: -0.3);

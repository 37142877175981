
.inline-form {
	display: inline;
}

.inline-action-form {
  display: inline-block;
}

fieldset {
  margin: 2em 0;
}

legend {
	font-family: $heading-font-family;
	font-size: 1.4em;
  background: #fff;
  padding: 0.2em 0.3em;
  border: $base-border;
  line-height: 1.2em;
}

select {
  margin-bottom: 0.5em;
  font-size: 1em;
  // padding: 0.5em;
  border: $base-border;
  background: #fff;

  option {
    padding: 0.2em 0.5em;
    // background: #fff;
  }
}

.fieldset_intro {
	font-family: $heading-font-family;
	padding-bottom: 1em;
	margin-bottom: 1em;
}

.form-field {
	border-left: 5px solid $base-border-color;
	padding: 0.1em 0 0.1em 1em;
	margin: 1em 0;

	.hint {
		margin: 0;
		font-size: 0.85em;
		color: $dark-gray;
	}
}

.form-field-inline {
  label, input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea, select[multiple=multiple], select {
    display: inline-block;
    width: auto;
  }
}

.button .fa {
  margin-right: 0.3em;
}

.button-icon .fa {
  margin-right: 0;
}

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea, select[multiple=multiple], textarea {
  &.error {
    background: transparentize(red, 0.8);
  }
}

textarea.large {
  min-height: 150px;
}

.switch {
  $brand-main: #477DCA !default;
  $base-background-color: white !default;
  $switch-width: 52px;
  $switch-padding: 2px;
  $switch-height: 32px;
  $switch-radius: $switch-height;
  $knob-size: $switch-height - ($switch-padding * 2);
  $knob-radius: $switch-height - ($switch-padding * 2);
  $knob-width: $knob-size;
  $switch-background: $base-background-color;
  $switch-border-background: darken($base-background-color, 9%);
  $switch-shadow: 0 2px 5px transparentize(black, 0.6);

  border-radius: $switch-radius;
  cursor: pointer;
  display: inline-block;
  height: $switch-height;
  position: relative;
  width: $switch-width;

  input[type="checkbox"] {
    display: none;

    + .checkbox {
      @include transition(all 0.3s ease);
      background: $switch-border-background;
      border-radius: $switch-radius;
      border: none;
      cursor: pointer;
      height: $switch-height;
      margin: 0;
      padding: 0;
      position: relative;
      width: $switch-width;
      z-index: 0;

      &:before {
        @include position(absolute, 2px 0 0 2px);
        @include transform(scale(1));
        @include transition(all 0.3s ease);
        background: $switch-background;
        border-radius: $switch-radius;
        content: "";
        height: $knob-radius;
        width: $switch-width - ($switch-padding * 2);
        z-index: 1;
      }

      &:after {
        @include position(absolute, 2px 0 0 2px);
        @include transition(all 0.3s ease);
        @include size($knob-size);
        background: $switch-background;
        border-radius: $knob-radius;
        box-shadow: $switch-shadow;
        content: "";
        z-index: 2;
      }
    }

    &:checked {
      + .checkbox {
        background: $brand-main;

        &:before {
          @include transform(scale(0));
        }

        &:after {
          left: $switch-width - $knob-width - ($switch-padding);
        }
      }
    }
  }
}

.upload-current {
	img {
		width: 145px;
		height: 145px;
	}
}

.upload-new {
  display: none;
}

.pagination {
    margin: 3em 0;
    height: 30px;
    li {
        float: left;
        span, a {
            display: block;
            padding: 5px 12px;
            border-width: 1px;
            border-style: solid;
            border-color: #ddd;
            border-left: 0;
        }

        a:hover {
            background-color: #ddd;
            color: black;
        }

        &:first-child {
           span, a {
                border-left: 1px solid #ddd;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
           } 
        }

        &:last-child {
           span, a {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
           } 
        }

    }
}

